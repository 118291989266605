//import vue router
import { createRouter, createWebHistory } from 'vue-router'

//import store vuex
import store from '@/store'

//define a routes
const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import( /* webpackChunkName: "register" */ '@/views/404/Index.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import( /* webpackChunkName: "register" */ '@/views/auth/Register.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import( /* webpackChunkName: "login" */ '@/views/auth/Login.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import( /* webpackChunkName: "dashboard" */ '@/views/dashboard/Index.vue'),
        meta: {
            //chek is loggedIn
            requiresAuth: true
        }
    },
    {
        path: '/donation',
        name: 'donation.index',
        component: () => import( /* webpackChunkName: "donationIndex" */ '@/views/donation/Index.vue'),
        meta: {
            //chek is loggedIn
            requiresAuth: true
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import( /* webpackChunkName: "profile" */ '@/views/profile/Index.vue'),
        meta: {
            //chek is loggedIn
            requiresAuth: true
        }
    },
    {
        path: '/profile/password',
        name: 'profile.password',
        component: () => import( /* webpackChunkName: "profilePassword" */ '@/views/profile/Password.vue'),
        meta: {
            //chek is loggedIn
            requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'home',
        component: () => import( /* webpackChunkName: "home" */ '@/views/home/Index.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/category',
        name: 'category.index',
        component: () => import( /* webpackChunkName: "categoryIndex" */ '@/views/category/Index.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/category/:slug',
        name: 'category.show',
        component: () => import( /* webpackChunkName: "categoryShow" */ '@/views/category/Show.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/campaign',
        name: 'campaign.index',
        component: () => import( /* webpackChunkName: "campaignIndex" */ '@/views/campaign/Index.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/campaign-program/:id',
        name: 'campaign.program.index',
        component: () => import( /* webpackChunkName: "campaignIndex" */ '@/views/campaignProgram/Index.vue'),
    },
    {
        path: '/campaign/:slug',
        name: 'campaign.show',
        component: () => import( /* webpackChunkName: "campaignShow" */ '@/views/campaign/Show.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/campaign/:slug/kabar-terbaru',
        name: 'news.index',
        component: () => import( /* webpackChunkName: "campaignShow" */ '@/views/newsUpdate/Index.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/donation/create/:slug',
        name: 'donation.create',
        component: () => import( /* webpackChunkName: "donationCreate" */ '@/views/donation/Create.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/qurban/create/:slug',
        name: 'qurban.create',
        component: () => import( /* webpackChunkName: "donationCreate" */ '@/views/qurban/Create.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/search',
        name: 'search',
        component: () => import( /* webpackChunkName: "search" */ '@/views/search/Index.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import( /* webpackChunkName: "search" */ '@/views/privacyPolicy/Index.vue'),
        meta: { requiresAuth: false }
    },
]

//create router
const router = createRouter({
    history: createWebHistory(),
    routes // <-- routes,
})

//define route for handle authentication
router.beforeEach(async (to) => {
    if (to.meta.requiresAuth && !store.getters['auth/isLoggedIn']) {
        if (to.name.includes('login')) {
            return true;
        } else {
            return {
                name: 'login',
                force: true
            }
        }
    }
})

router.onError(error => {

    if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
        window.location.reload()
    }

})

export default router